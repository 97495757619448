.disabledRow{
    cursor: not-allowed;
    color: lightgray;
}
.disabledRow .ant-btn{
    cursor: pointer!important;
    pointer-events: all!important;
}
.disabledRow:hover{
    background-color: transparent;
}
.disabledRow .ant-checkbox-wrapper{
    cursor: not-allowed;
    pointer-events: none;
}
.disable-btn{
    color: red;
    border-color: red;
}
.disable-btn:hover{
    color: rgb(219, 57, 57);
    border-color: rgb(219, 57, 57);
}
.space-left {
    margin-right: 20px;
}
.enable-btn{
    color: green;
    border-color: green;
}
.enable-btn:hover{
    color: rgb(58, 170, 58);
    border-color: rgb(58, 170, 58);
}
.enable-btn:active{
    color: green!important;
    background-color: green!important;
}