.display{
    display: block;
}
.not-display{
    display: none
}
.tabContent{
    width: fit-content;
    padding: 4px 10px;
    height: 35px;
    border: 2px solid lightgray;
    margin-bottom: 0;
    font-size: 0.9rem;
}
.tabContentContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}