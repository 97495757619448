@import "~antd/dist/antd.css";
@import url("https://fonts.cdnfonts.com/css/gordita");

:root {
  /* Colors: */
  --unnamed-color-616e73: #616e73;
  --unedited-text: #707070;
  --success: #21b81c;
  --text-color: #232323;
  --primary: #009444;
  --error: #f32424;
}

body {
  margin: 0;
  font-family: "Gordita", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table {
  margin: 16px 0 0 0;
}

.ant-table-thead {
  background: #ecf0f1;
}

.ant-table-tbody {
  background: #f5f8f9;
}

.ant-table-cell-row-hover{
  background: #F9F3EA !important;
}

.ant-checkbox.ant-checkbox-checked::after{
  background: #F9F3EA !important;

}

.input__group {
  border-radius: 3px 3px 3px 3px;
  color: #000;
  padding: 0.8em 1.2em;
  height: 54px;
  width: 100%;
}

.custom__primary__button,
.ant-btn-primary.ant-btn-sm {
  background: #009444;
  border: 1px #009444 solid;
}

.custom__primary__button:hover,
.ant-btn-primary.ant-btn-sm:hover {
  background: #009444;
  border: 1px #009444 solid;
}

.custom__ghost__button {
  color: #009444 !important;
  border: 1px #009444 solid !important;
}

.ant-tag {
  border-radius: 1.7em !important;
  padding: 0.3em 1em !important;
}

.ant-form-item-label label{
  font-weight: bold !important;
}
