.customized__search__bar {
  width: 100%;
  padding: 0.3em 0.8em;
  border: 1px solid #d9d9d9;
}

.search__icon {
  position: absolute;
  margin: 0.7em 0 0 0.8em;
  font-size: 1em;
}

.customized__search__bar::placeholder {
  padding-left: 2em;
}

.customized__search__bar:focus {
  border-color: #40a9ff;
  outline: 0;
}

.results {
  padding: 0 0 1.2em 0;
}

.search__results {
  height: 250px;
  overflow-y: auto;
}

.search__result {
  height: 53px;
  align-items: center;
  border-bottom: 0.7px solid #d4dde6;
}

.search__result.selected{
  background: #f9f3ea;
}

.search__result:hover {
  background: #f9f3ea;
  cursor: pointer;
}

.search__result__name {
  display: flex;
  padding: 0 0.5em;
}

.search__result__name p {
  margin-bottom: 0;
  margin-left: 1.3em;
}

.ant-progress-bg{
  height: 15px !important;
  border-radius: 5px !important;
  background-color: #009444;
}