:root {
  /* Colors: */
  --unnamed-color-616e73: #616e73;
  --unedited-text: #707070;
  --success: #21b81c;
  --text-color: #232323;
  --primary: #009444;
  --error: #f32424;
}

.login__page {
  overflow: hidden;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
}

.left__side {
  color: #fff;
  position: relative;
  padding-right: 0;
  padding-left: 0;
  background: transparent linear-gradient(215deg, #118446 0%, #087239 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.left__side .inner__container {
  position: absolute;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.left__side .icon {
  background: #41996a 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  padding: 1.4em 1em;
}

.icon__container {
  text-align: right;
  padding-right: 1em;
}

.left__side .pattern__one {
  height: 50%;
  width: 50%;
  top: 0;
  left: 0;
  float: right;
  margin: 0;
  background-image: url("../../images/design.png");
}

.left__side .pattern__two {
  position: absolute;
  height: 50%;
  width: 50%;
  bottom: 0;
  background-image: url("../../images/design-1.png");
}

.right__side {
  background-color: #f5f8f9;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.right__side .right__div {
  width: 70%;
}

::placeholder {
  padding: 0 0.4em;
}

.login__form {
  margin-top: 3em;
}

.login__button {
  border-radius: 3px 3px 3px 3px;
  background-color: var(--primary);
  width: 100%;
  height: 54px;
  border-color: var(--primary);
  color: #fff;
  margin-bottom: 2em;
}

.login__button:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.forgot__password {
  color: var(--primary);
  text-decoration: none;
  font-weight: bold;
}

.back__to {
  color: var(--primary);
  text-decoration: none;
  font-weight: bold;
}

.back__to__2 {
  margin-top: 0.5em;
  color: var(--primary);
  text-decoration: none;
}

.logo {
  position: absolute;
  bottom: 0;
  left: 38%;
  display: flex;
  gap: 1em;
}
