.tabs {
  margin: 0 2em;
  display: flex;
}

.user__type__tab {
  padding: 0.2em 1.2em;
  border-radius: 20px;
  background: #ecf0f1;
  cursor: pointer;
  margin: 0 0 0 16px;
}

.userTab__active {
  background: #8499b1 0% 0% no-repeat padding-box;
  color: #fff;
}
.displayNone{
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
  height: 54px;
}
.ant-select-single .ant-select-selector{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}