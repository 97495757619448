.overview__card,
.channel__card,
.ticket__type__card,
.countdown__card {
  border: 1.2em #eff5f6 solid;
}

.channel__card,
.countdown__card {
  margin: 3em 0;
}
.space__btn {
  padding-bottom: 20px;
}

.countdown__card {
  background: transparent
    linear-gradient(245deg, var(--primary) 0%, #0d2851 100%) 0% 0% no-repeat
    padding-box;
  background: transparent linear-gradient(245deg, #1c4584 0%, #0d2851 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.section__title {
  color: #009444 !important;
}

.stats__items {
  margin-top: 2em;
}

.sub__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 7em;
}

.sub__item {
  font-size: 14px;
  color: #BAC5C8;
}

.divider {
  height: 60px;
}

.ticket__space {
  margin-right: 10px;
}