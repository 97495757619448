:root {
  /* Colors: */
  --unnamed-color-616e73: #616e73;
  --unedited-text: #707070;
  --success: #21b81c;
  --text-color: #232323;
  --primary: #1c4584;
  --error: #f32424;
}

.side__menu {
  background: transparent linear-gradient(184deg, #118446 0%, #087239 100%) 0%
    0% no-repeat padding-box;
  overflow-y: auto;
  align-self: stretch;
}

.ticket__side__menu {
  background: transparent linear-gradient(193deg, #118446 0%, #087239 100%) 0%
    0% no-repeat padding-box;
  overflow-y: auto;
  align-self: stretch;
}

.ticket__details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em;
  background: #fff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.ticket__steps {
  padding: 3em;
}

.custom__logo {
  background: inherit;
  margin: 2.8em 0 2em 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.side__menu .menu {
  background: none !important;
}

.ant-menu-item {
  color: #fff;
}

.sidebar__icon {
  margin-right: 1em;
}

.ant-menu-item:hover {
  color: #fff !important;
}

.ant-menu-item.ant-menu-item-selected img {
  filter: brightness(0);
}

.ant-menu-item.ant-menu-item-selected {
  /* background: #fff 0% 0% no-repeat padding-box !important; */
  opacity: 1;
  color: #009444;
  border: #fff !important;
}

.ant-menu-item-selected:hover {
  color: #009444 !important;
}

.inner__layout {
  max-height: 100vh;
  overflow-y: auto;
}

.header {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  padding: 0;
}

.avatar {
  background-color: #4f8472;
  margin-left: 10px;
}

.content {
  background: #f5f8f9;
  overflow: initial;
  padding: 2em 2em 0 2em;
  overflow-x: auto;
}

.trigger {
  left: 4.3em;
  top: 0.7em;
  align-self: center;
  position: absolute;
  padding: 0.5em;
  border-radius: 50%;
  color: #fff;
  background-color: #118446;
}

.move__right {
  left: 13em;
}

.right__side__bar {
  padding: 2em 0;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.4em;
  text-align: center;
}

.right__side__bar .item {
  cursor: pointer;
}

/* Steps starts*/
.ant-steps-item-active .ant-steps-item-icon {
  background-color: #fff !important;
  border: 1px #fff solid;
}

.ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
  color: #000 !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #009444;
  border: 1px #fff solid;
}

.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-title {
  color: #fff !important;
}
/* Steps Ends */
